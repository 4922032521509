import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import "./addmn.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import pending from "../../img/pending.png";
import success from "../../img/checked.png";
import error from "../../img/close.png";

function Award() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [profile, setProfile] = useState(null);
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");
			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		axios
			.get(`https://server.foreverlovevn.com/payment/paymentus`, {})
			.then((res) => {
				setData(res.data.data);
			})
			.catch((err) => function () {});
		axios
            .get(`https://server.foreverlovevn.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
	}, []);

	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Lịch sử nhận thưởng
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				{data ? (
					<div className="content_profile">
						{data?.map((item, key) => (
							<>
								{item.type_payment === "THƯỞNG" ? (
									<>
										<div key={key} className="history_payment">
											<div style={{ display: "flex" }}>
												{item.status_payment === "Success" &&
												item.type_payment === "THƯỞNG" ? (
													<img
														src={success}
														style={{
															width: "35px",
															height: "35px",
															backgroundColor: "white",
														}}
													/>
												) : null}
												{item.type_payment === "THƯỞNG" ? (
													<>
														<div className="type_payment">
															<div className="typepayment_detail">THƯỞNG</div>
															<div className="date_time">
																{formatDate(new Date(item.createdAt))}
															</div>
															<div
																style={{ color: "green" }}
																className="typepayment_detail"
															>
																Thành công
															</div>
														</div>
													</>
												) : null}
											</div>
											<div style={{ color: "green" }} className="money_pamn">
												+{item.money}
											</div>
										</div>
										<hr className="line-pay" />
									</>
								) : null}
							</>
						))}
					</div>
				) : (
					<></>
				)}

				<Footer profile={profile}/>
			</div>
		</>
	);
}
export default Award;
