import { Box, Container, Input, TableBody, TableCell, TableHead, Button, TextField } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import React, { useEffect, useState, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function SettingNotify() {
    const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];
    const [profile, setProfile] = useState(null);
    const [content, setContent] = useState("");
    const [cskh, setCskh] = useState("");
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`https://server.foreverlovevn.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res?.data?.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                window.location.href = "/login";
            });
        axios
            .get(`https://server.foreverlovevn.com/setting/get`, {})
            .then((res) => {
                setContent(res?.data?.data[0]?.thongbao);
                setCskh(res?.data?.data[0]?.cskh);
            })
            .catch(() => {
                setContent("");
            });
    }, []);
    const handleChangeContent = (value) => {
        setContent(value);
    };
    const handleChangeA = (e) => {
        setCskh(e.target.value);
    }
    const handleSubmit = () => {
        if (profile?.permission?.edit != 1) {
            return swal("Bạn không có quyền", "", "warning");
        }
        const formData = {
            thongbao: JSON.stringify(content),
            cskh
        };
        axios
            .put(`https://server.foreverlovevn.com/setting/update`, formData)
            .then((res) => {
                swal("Cập nhật thành công!", "", "success");
            })
            .catch((res) => swal("Cập nhật thất bại", "", "error"));
    };
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                ["link", "image"],
                ["clean"]
            ],
            /*handlers: {
                image: () => {
                    console.log("a");
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.click();
                    input.onchange = async () => {
                        const file = input.files[0];
                        if (file) {
                            await handleImageUpload(file); // Gọi hàm upload ảnh
                        }
                    };
                }
            }*/
        }
    }));
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 8
                        }}>
                        <Container maxWidth={false}>
                            <div style={{ fontSize: "25px", fontWeight: 700 }}>Cài đặt trang thông báo</div>
                            <ReactQuill value={content} onChange={handleChangeContent} modules={modules} formats={formats} />
                            <br />
                            <input name="cskh" defaultValue={cskh} onChange={(e) => handleChangeA(e)} />
                            <br />
                            <Button onClick={handleSubmit}>Lưu</Button>
                        </Container>
                    </Box>
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default SettingNotify;
