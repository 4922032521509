import "./footer.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CachedIcon from "@mui/icons-material/Cached";
import { Link } from "react-router-dom";
import swal from "sweetalert";
//import LiveChat from "react-livechat";

function Footer(props) {
    var profile = props.profile;
    const [cskh, setCskh] = useState("");
    const data = [
        {
            icon: <HomeOutlinedIcon />,
            title: "Trang chủ",
            to: "/"
        },
        {
            icon: <CachedIcon />,
            title: "Làm mới",
            to: "/"
        },
        {
            icon: <TrendingUpIcon />,
            title: "Xu hướng",
            to: "/trend"
        },
        {
            icon: <Person3OutlinedIcon />,
            title: "Cá nhân",
            to: "/mine"
        }
    ];
    useEffect(() => {
        const interval = setInterval(() => {
            axios.post(`https://server.foreverlovevn.com/auth/updatestatus`, { online: true }).then((res) => {});
        }, 5000);
        return () => clearInterval(interval);
    }, [profile]);
    useEffect(() => {
        axios.get(`https://server.foreverlovevn.com/setting/get`, {}).then((res) => {
            setCskh(res?.data?.data[0]?.cskh);
        });
    }, []);
    return (
        <>
            <div className="bg-menu">
                {profile ? (
                    <>
                        <div className="detail_id">
                            <div className="item_id">ID : {Number(profile?.iduser) + 3000}</div>
                            <div style={{ fontWeight: "500" }}>
                                Số điểm: <b>{props.profile.money}</b>
                            </div>
                            {/*<LiveChat
								license={16484763}
								onChatLoaded={(ref) =>
									setTimeout(function () {
										window.LC_API.hide_chat_window();
									}, 3000)
								}
							/>*/}
                        </div>
                    </>
                ) : null}
                <div className="footer">
                    <div className="item-footer">
                        <Link style={{ textDecoration: "none" }} to="/">
                            <div className="icon_footer">
                                <HomeOutlinedIcon />
                            </div>
                            <div className="title_footer">Trang chủ</div>
                        </Link>
                    </div>
                    <div className="item-footer">
                        <div onClick={() => window.location.reload()}>
                            <div className="icon_footer">
                                <CachedIcon />
                            </div>
                            <div className="title_footer">Làm mới</div>
                        </div>
                    </div>
                    <div className="item-footer">
                        <div>
                            <div className="icon_footer_center">
                                <img src={require('../../img/menu.png')}/>
                            </div>
                        </div>
                    </div>
                    <div className="item-footer">
                        <Link style={{ textDecoration: "none" }} to="/mine">
                            <div className="icon_footer">
                                <Person3OutlinedIcon />
                            </div>
                            <div className="title_footer">Cá nhân</div>
                        </Link>
                    </div>
                    <div className="item-footer">
                        <div style={{ cursor: "pointer" }} className="livechat_button" onClick={() => {} /*window.LC_API.open_chat_window()*/}>
                            <a style={{ textDecoration: "none" }} onClick={() => swal("", "Quý khách liên hệ chuyên viên để được hỗ trợ","info")}>
                                <div className="icon_footer">
                                    <HeadsetMicOutlinedIcon />
                                </div>
                                <div className="title_footer">CSKH</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;
