import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import "./profile.css";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import CachedIcon from "@mui/icons-material/Cached";
import Footer from "../../components/Footer/Footer";
import swal from "sweetalert";
import Confetti from "react-confetti";

function Home() {
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(3);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [err, setErr] = useState(null);
    const [active, setActive] = useState(null);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [state, setState] = useState(null);
    const [total, setTotal] = useState(null);
    const [isShow, setShow] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [choose, setChoose] = useState(null);
    const [content, setContent] = useState("");
    const {
        watch,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (start === false) {
            axios
                .get(`https://server.foreverlovevn.com/auth/getUser`, {})
                .then((res) => {
                    setProfile(res.data.data);
                })
                .catch(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
            axios.get(`https://server.foreverlovevn.com/bet/get`).then((res) => {
                setBet(res.data.data);
                setDulieunhap(new Date(res.data.data.createdAt));
                setStart(true);
            });
            axios
                .get(`https://server.foreverlovevn.com/bet/getallbet`, {})
                .then((res) => {
                    setTotal(res.data.data);
                })
                .catch(() => setTotal(null));
            axios
                .get(`https://server.foreverlovevn.com/setting/get`, {})
                .then((res) => {
                    setContent(res?.data?.data[0]?.thongbao);
                })
                .catch(() => {
                    setContent("");
                });
        }
    }, [start]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`https://server.foreverlovevn.com/notification/getnotifi`, {}).then((res) => {
                if (res?.data.data[0]) {
                    setVisible({
                        money: res?.data?.data[0]?.money.toLocaleString(),
                        id: res?.data?.data[0]?._id,
                        msg: res?.data?.data[0]?.msg
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            setShowConfetti(true);
            swalInst = swal({
                title: "Xin chúc mừng !!!",
                text: `Chúc mừng quý khách mang ID ${
                    Number(profile?.iduser) + 3000
                } đã trúng giải thưởng ngẫu nhiên trị giá ${data.money.toLocaleString()} VNĐ. Vui lòng liên hệ CSKH để biết thêm chi tiết.`,
                //text: data.msg,
                className: "bg-gift",
                //icon: GiftImg,
                buttons: {
                    submit: "Đồng ý"
                }
            }).then(() => {
                setShowConfetti(false);
                axios.post("https://server.foreverlovevn.com/notification/seen", {
                    id: data.id
                });
            });
            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);
    useEffect(() => {
        let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);

        let myTimeout;
        // if (curTime_second <= 0) {
        // 	axios.get(`https://server.foreverlovevn.com//bet/get`).then((res) => {
        // 		let date = new Date();
        // 		let date1 = new Date(res.data.data[0].createdAt);
        // 		if (date - date1 > 180000) {
        // 			setBet(null);
        // 		} else {
        // 			setBet(res.data.data[0]);
        // 			setDulieunhap(new Date(res.data.data[0].createdAt));
        // 		}
        // 	});
        // }

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 180 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 180 || curTime_second <= 0) {
                setStart(false);
                setMinute(3);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const onChoose = (e) => {
        setChoose(e);
        setActive(e);
        setShowPopup(true);
    };
    const onSubmit = async (data) => {
        if (quantity == 0) {
            swal("Thông báo", "Vui lòng nhập số điểm", "info");
            return;
        }
        if (!choose) {
            swal("Thông báo", "Vui lòng chọn ô", "warning");
            return;
        }
        const formDate = {
            id: bet._id,
            money: quantity,
            bet: choose
        };

        axios
            .post(`https://server.foreverlovevn.com/history/choose`, formDate)
            .then((res) => {
                swal("Thành công", "Nhập thành công", "success");
                setStart(false);
                setErr(res.data.message);
                setShowPopup(false);
            })
            .catch((res) => swal("error", res.response.data.message, "error"));
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const [quantity, setQuantity] = useState(100);
    const handleSetValue = (value) => {
        setQuantity(value);
    };

    const resetForm = () => {
        setQuantity(100);
        setActive(null);
        setState(null);
        setShowPopup(false);
    };
    const quantities = [20, 30, 50, 100, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000];
    return (
        <>
            <div className="app1">
                <div style={{ background: "#ff769a" }}>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <div className="text1">
                            Số liệu vs quay thưởng phiên hiện tại: <b style={{ fontSize: "110%" }}>{total ? total[0]?.id_bet : "----"}</b>
                        </div>
                        <div style={{ width: "35px", textAlign: "right", marginLeft: "auto" }}>
                            <div className="info-icon" onClick={openPopup}>
                                <InfoIcon />
                            </div>
                        </div>
                    </div>
                    <div className="result_last">
                        {total != null && (
                            <ul data-v-4a0db828="" className="clear-fix list">
                                <li data-v-4a0db828="" className="num-item ball   i0 number1 numberlist">
                                    {total[0].result.split(" ")[0]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i1 number2 numberlist">
                                    {total[0].result.split(" ")[1]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i2 number3 numberlist">
                                    {total[0].result.split(" ")[2]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i3 number4 numberlist">
                                    {total[0].result.split(" ")[3]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i4 number5 numberlist">
                                    {total[0].result.split(" ")[4]}
                                </li>
                            </ul>
                        )}
                    </div>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <div className="text1">
                            Số liệu vs quay thưởng phiên tiếp theo: <b>{total ? Number(total[0]?.id_bet + 3) : "----"}</b>
                        </div>
                        <div style={{ width: "100px", justifyContent: "end", marginLeft: "auto", display: "flex", alignItems: "center" }}>
                            <RestoreIcon style={{ marginRight: "3px" }} /> 0{minute} : {second < 10 ? "0" : ""}
                            {second}
                        </div>
                    </div>
                </div>
                <h1 style={{ fontSize: "18px" }}>VÒNG QUAY KẾT NỐI YÊU THƯƠNG</h1>
                <div className="record_bet">
                    <nav data-v-260e3e17="" data-v-0f9d40fc="" className="tab-box lottery-game-tab" data-v-435dc89a="">
                        <div data-v-260e3e17="" className="tab-item tab-item-active">
                            VÒNG QUAY TÌNH YÊU
                        </div>
                        {/*<div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game1")}>
                            PHÒNG 1
                        </div>
                        <div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game5")}>
                            PHÒNG 5
                        </div>*/}
                    </nav>
                    <ul data-v-0f9d40fc="" className="lottery-code-panel-body">
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 1 && "active"}`}
                                onClick={(e) => {
                                    onChoose(1);
                                }}>
                                NHẬP
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                        </li>
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 2 && "active"}`}
                                onClick={(e) => {
                                    onChoose(2);
                                }}>
                                XUẤT
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                        </li>
                        {/*<li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 3 && "active"}`}
                                onClick={(e) => {
                                    onChoose(3);
                                }}>
                                IN
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                        </li>
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 4 && "active"}`}
                                onClick={(e) => {
                                    onChoose(4);
                                }}>
                                OUT
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                        </li>*/}
                    </ul>
                    <div className="vqmm">
                        <div class="vq-img">
                            <img src={require("../../img/spin.png")} className="rotate" />
                        </div>
                        <div className="vq-text">
                            <img src={require("../../img/textvqmm.png")} />
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="popup-hd">
                    <div className="popup-hd-header">
                        <h3>QUY ĐỊNH</h3>
                        <div className="popup-hd-close" onClick={closePopup}>
                            &times;
                        </div>
                    </div>
                    <div className="popup-hd-content">
                        <div className="contentHtml" dangerouslySetInnerHTML={{ __html: content.replaceAll('"','') }} />
                    </div>
                </div>
            )}
            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            {showPopup && (
                <form className="lottery-bet-input" onSubmit={handleSubmit(onSubmit)}>
                    <div className="chip-row" style={{ flexFlow: "row wrap" }}>
                        {quantities.map((value) => (
                            <div
                                key={value}
                                className={`chip ${quantity == value ? "active" : ""}`}
                                onClick={() => handleSetValue(value)}
                                style={{ width: "15%", marginBottom: "5px" }}>
                                {value}
                            </div>
                        ))}
                    </div>
                    <div className="bet-input">
                        <div style={{ cursor: "pointer" }} onClick={resetForm}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#555"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                style={{ position: "relative", top: "2px", margin: "0 3px 0 0" }}>
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 7l16 0" />
                                <path d="M10 11l0 6" />
                                <path d="M14 11l0 6" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                            </svg>
                            Đóng
                        </div>
                        <div>
                            <input {...register("money", { required: true })} type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} name="money" />
                        </div>
                        <div>
                            <button type="submit">Xác nhận</button>
                        </div>
                    </div>
                </form>
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Home;
