import Footer from "../../components/Footer/Footer";
import "./home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import "./profile.css";
import "./addmn.css";
import "./cskh.css";
import { useEffect, useState } from "react";
import LiveChat from "react-livechat";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
function AddMoney() {
	const [profile, setProfile] = useState(null);
	const [bank, setBank] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");
			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	useEffect(() => {
		axios
            .get(`https://server.foreverlovevn.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
		axios
			.get(`https://server.foreverlovevn.com/bank/getBank`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => setBank(null));
	}, []);
	const onSubmit = (data) => {
		const formData = {
			money: data.money,
			type_payment: "NẠP",
			detail: "Nạp điểm bằng chuyển khoản ngân hàng",
			status_payment: "Pending",
			user: profile._id,
		};
		axios
			.post(`https://server.foreverlovevn.com/payment/withDraw`, formData)
			.then((res) => {
				navigate("/mine");
			})
			.catch((err) =>
				setError("money", {
					type: "minLength",
					message: "Lỗi giao dịch 404!",
				})
			);
	};
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const initLiveChat = () => {
			if (window.LC_API) {
				window.LC_API.on_after_load = () => {
					window.LC_API.open_chat_window();
					setLoading(false);
				};
			} else {
				setTimeout(initLiveChat, 100);
			}
		};

		initLiveChat();
	}, []);
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Nạp điểm
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				<div
					className="content_profile"
					style={{ color: "#333", padding: "15px" }}
				>
					<div>
						Chuyển khoản tới
						<br /> STK: <b>219412345</b>
						<br /> Ngân hàng Quốc Tế VIB
						<br /> Chủ tài khoản: <b>NGUYEN THI THUY</b>
						<br /> Nội dung chuyển khoản:{" "}
						<b>fml {profile ? <span>{profile.username}</span> : null}</b>
						<br />
						<br />
						Sau khi chuyển khoản thành công, nhập thông tin OUT đây và bấm xác
						nhận
					</div>
					<form
						style={{ display: "block", padding: "0 15px" }}
						onSubmit={handleSubmit(onSubmit)}
					>
						<div>
							<div>
								<input
									className="ipadd"
									type="number"
									{...register("money", { required: true })}
									placeholder="Nhập số điểm đã chuyển khoản"
								/>
							</div>
							<div>
								{" "}
								<input
									className="ipadd"
									type="text"
									{...register("detail", { required: true })}
									placeholder="Số tài khoản bạn dùng để chuyển điểm"
								/>
							</div>
							{errors.money ? (
								<p style={{ color: "red" }}>{errors.money.message}</p>
							) : null}
							<button type="submit" className="btn-submit">
								Xác nhận
							</button>
						</div>
					</form>
				</div>

				<Footer profile={profile}/>
			</div>
			{/*<div className="app1 cskh">
        <div className="info_profile">
          <div className="cycle_bet">
            <span className="info_bet">Nạp điểm</span>
          </div>
        </div>
		{loading && <div style={{ color: "#333", margin: "20px 0 0" }}>VUI LÒNG CHỜ TRONG GIÂY LÁT</div>}
        <LiveChat license={16484763} />
		<Footer profile={profile}/>
							</div>*/}
		</>
	);
}
export default AddMoney;
